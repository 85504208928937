// @flow
import * as React from 'react';
import Glyph from '../core/Glyph.react';

import './PublicationDiversity.css';

type Props = {
  diversity: ?boolean,
}

const PublicationDiversity = (props: Props): React.Node => {
  const diversity = props.diversity;
  if (diversity !== true) {
    return null;
  }

  return (
    <div className="PublicationDiversity">
      <div className="PublicationDiversityRecognition" >
        <Glyph glyph="award" />
      </div>
      <div className="PublicationDiversityText" >
        Diversity & Inclusion Recognition
      </div>
    </div>
  );
}

export default PublicationDiversity
