// @flow
import * as React from 'react';
import Section from '../core/Section.react';
import ReactMarkdown from 'react-markdown';

import './ServiceDiversityAndInclusion.css';

import {DIVERSITY_INCLUSION_OUTREACH_TEXT} from '../data/ServiceData';


const ServiceDiversityAndInclusion = (): React.Node => (
  <Section
    className="ServiceDiversityAndInclusion"
    label="Diversity, Inclusion & Outreach">
    <ReactMarkdown>
      {DIVERSITY_INCLUSION_OUTREACH_TEXT}
    </ReactMarkdown>
  </Section>
);

export default ServiceDiversityAndInclusion;
