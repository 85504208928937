// @flow
import * as React from 'react';
import CVPageViewer from './CVPageViewer.react';
import './CVDocumentViewer.css'

type Props = {
  documentWidth: ?number,
  numPages: number,
}
type State = {|
|};

class CVDocumentViewer extends React.Component<Props, State> {
  divRef: ?HTMLDivElement;

  _renderPage = (pageNumber: number) => {
    const divWidth = this.divRef && this.divRef.offsetWidth;
    const width = this.props.documentWidth || divWidth || 840;
    return (
      <CVPageViewer
        key={pageNumber}
        pageNumber={pageNumber}
        documentWidth={width - 40 /* for "padding: 20px" */}
      />
    );
  }

  render(): React.Node {
    const pageNumbers = [];
    for (let i = 0; i < this.props.numPages; i++) {
       pageNumbers.push(i + 1);
    }

    return (
      <div
        className="CVDocumentViewer"
        ref={ref => this.divRef = ref}>
        {pageNumbers.map(this._renderPage)}
      </div>
    );
  }
}

export default CVDocumentViewer;
