// @flow strict-local
import * as React from 'react';
import PublicationSecondaryRow from '../PublicationSecondaryRow.react';
// $FlowFixMe
import ReactMarkdown from 'react-markdown';

type Props = {
  authors: string,
  note?: string,
}

const renderNote = (note: ?string): ?React.Node => {
  if (note == null) {
    return null;
  }

  return (
    <div style={{
      'marginLeft': '12px',
      'verticalAlign': 'sub',
      'fontSize': 'smaller',
      'fontStyle': 'italic' }}>
      {note}
    </div>
  );
}

const PublicationAuthors = (props: Props): React.Node => {
  return (
    <PublicationSecondaryRow className="PublicationAuthor" glyph="user">
      <ReactMarkdown  >
        {props.authors}
      </ReactMarkdown>
      {renderNote(props.note)}
    </PublicationSecondaryRow>
  );
}

export default PublicationAuthors
