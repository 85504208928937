// @flow

import type {Node} from 'react';
import type {CovidResearch} from '../data/CovidResearch.js';

import React, {useState} from 'react';
import Section from '../core/Section.react';
import ReactMarkdown from 'react-markdown';
import Glyph from '../core/Glyph.react';

import {Research, mapStringToUri} from '../data/CovidResearch';

import './Covid.css';


/*

Content replace with the use of iframe

function Project(props: CovidResearch): Node {
  const [compressed, setCompressedState] = useState(false);

  const {title, markdown} = props;

  return (
    <div className="Project" >
      <div className="ProjectRow" onClick={() => setCompressedState(!compressed)} >
        <div className="ProjectGlyph">
          <Glyph
            glyph={compressed ? 'plus-square' : 'minus-square'}
            fixedWidth={true}
            size='xs'
          />
        </div>
        <div className="ProjectTitle">
          {title}
        </div>
      </div>
      {
        compressed
        ? null
        : <div className="ProjectBody">
            <ReactMarkdown
              source={markdown}
              transformImageUri={mapStringToUri}
            />
          </div>
      }
    </div>
  );
}

const renderProject = (row: CovidResearch, index: number) => (
  <Project
    key={index}
    {...row}
  />
);

class CovidResearchSection extends React.Component<{||}, {||}> {
  divRef: ?HTMLDivElement;
  
  render(): React.Node {
    ref={ref => this.divRef = ref}>
    return (
      <Section
        label="Covid-19 Research"
        className="Covid">>
        <div className="CovidText"> <p>Please visit <a href="https://covidadoptionproject.mpi-sws.org">covidadoptionproject.mpi-sws.org</a>.</p></div>
        <div ref={ref => this.divRef = ref}>
        <iframe src="https://covidadoptionproject.mpi-sws.org"> </iframe>
        </div>
      </Section>
    );
  }
}
*/


/*

//  Old function.  Do not use. 
function CovidResearchSection(props: {}): Node {
  
  return (
    <Section label="Covid-19 Research" className="Covid">
      <div className="CovidText"> <p>Please visit https://covidadoptionproject.mpi-sws.org.</p></div>
   
      <div className = "CovidText">
<br/>
      
      </div>
      <div className = "CovidText">
      Questions? Contact <a href="mail-to:eredmiles@gmail.com">eredmiles@gmail.com</a>
      </div>
      {Research.map(renderProject)}
    </Section>
  );
};
*/

function CovidHeader(props: {}): Node {
  return (
    <div className="CovidHeader">
      <Glyph
        glyph="external-link"
        link="https://covidadoptionproject.mpi-sws.org"
        >
        <span style={{marginRight: '4px'}}>covidadoptionproject.mpi-sws.org</span>
      </Glyph>
    </div>
  );
}



function CovidResearchSection(props: {}): Node {

  return (
    <Section
      label="Covid-19 Research"
      className="Covid" 
      hideLabel={true}
      displayIframe={true}>
      <CovidHeader />
      <iframe src="https://covidadoptionproject.mpi-sws.org" style={{width: '100%', height: '78vh'}} />
    </Section>
  );
};

export default CovidResearchSection;
