// @flow
import * as React from 'react';
import Glyph from '../core/Glyph.react';

import './PublicationBestPaperRunnerUp.css';

type Props = {
  bestPaperRunnerUp: ?boolean,
}

const PublicationBestPaperRunnerUp = (props: Props): React.Node => {
  const bestPaperRunnerUp = props.bestPaperRunnerUp;
  if (bestPaperRunnerUp !== true) {
    return null;
  }

  return (
    <div className="PublicationBestPaperRunnerUp">
      <div className="PublicationBestPaperRunnerUpTrophy" >
        <Glyph glyph="trophy" />
      </div>
      <div className="PublicationBestPaperRunnerUpText" >
        Best Paper Runner Up
      </div>
    </div>
  );
}

export default PublicationBestPaperRunnerUp
