// @flow strict-local

// import Tofee from './ToffeeScreenshot.png';

export type CovidResearch = {|
    title: string,

    // https://github.com/syntax-tree/mdast
    markdown: string,
|}


function mapStringToUri(uri: string) {
  switch (uri) {
    // case 'toffee':
    //   return Tofee;

    default:
      throw `image ${uri} must be defined in the function mapStringToUri`;
  }

}

const dataaltruism = `
In this survey of 540 MTurkers we evaluated how people's reported willingness to install proximity-based contact tracing coronavirus apps that allow for supplementary opt-in location data donation varied based on the beneficiary of the data donation: scientific reseachers, themselves, or others.

To examine this question we used three scenarios:

*1) A numeric benefit to others scenario (with X=1, 10, or 20).*
> Please consider the following scenario.
> Imagine that there is a coronavirus mobile phone app that will use information about your location to provide you with information about whether you were exposed to someone infected with coronavirus.
> 
> Every day, you can choose whether or not to donate your location information. If you choose to donate your data, it will be shared without revealing your identity.

After answering two attention check questions, respondents were then asked: 
> Imagine that donating your information will allow <i>X</i> people in your region to get information about whether they have been exposed to coronavirus.
> 
> Would you be willing to install this app?

*2) A benefit to self scenario:*
> Please consider the following scenario.
> Imagine that there is a coronavirus mobile phone app that will use information about your location to provide you with information about whether you were exposed to someone infected with coronavirus.
> 
> Every day, you can choose whether or not to donate your location information. If you choose to donate your data, it will be shared without revealing your identity.

After answering two attention check questions, respondents were then asked: 
> Imagine that donating your information will improve the app’s ability to detect whether you were exposed to someone infected with coronavirus. 
> 
> Would you be willing to install this app?

*3) A benefit to scientific research scenario:*
> Imagine that there is a coronavirus mobile phone app that will allow you to donate data to scientific researchers to help with the fight against coronavirus.
> - This app will collect information about your location.<br>
> - This information will be stored only on your phone and nowhere else.
> 
> Every day, you can choose whether or not to donate your location information. If you choose to donate your data, it will be shared with researchers without revealing your identity.
> 
> Would you be willing to install this app?
`

const generalmethods = `
- We collect data either on Amazon Mechanical Turk or via online web panels with quota sampling to balance respondent demographics for age, race, gender, and income/education (depending on sample). Sample type is reported in the Image Credit section of a data image.
- All surveys receive expert feedback and cognitive testing before deployment. Survey methodology best practices for question randomization, attention checks, and etc. are followed.
- All analysis follows field best practices for regression modeling, cross validation, multiple testing, and etc.
`

const researchoutput = `
- [Will Americans Be Willing to Install COVID Apps?](https://blogs.scientificamerican.com/observations/will-americans-be-willing-to-install-covid-19-tracking-apps/) **Scientific American** 2020.
- [The Success of Contact Tracing Doesn’t Just Depend on Privacy.](https://www.wired.com/story/the-success-of-contact-tracing-doesnt-just-depend-on-privacy/) **Wired** 2020.
- [Quality issues may be the stumbling block in the race for contact tracing apps.](https://www.statnews.com/2020/07/28/quality-issues-stumbling-block-contact-tracing-apps/) **STAT** 2020.
- [Citizen Science Projects Offer a Model for Coronavirus Apps.](https://www.wired.com/story/citizen-science-projects-offer-a-model-for-coronavirus-apps/) **Wired** 2020.
    - [User perceptions of Data Donation for COVID19 (survey methodology report)](https://www.microsoft.com/en-us/research/publication/covid19-data-donation-survey-methodology-summary/)
- [Americans’ Willingness to Install a COVID-19 Tracking App: The Role of App Distributor.](https://firstmonday.org/ojs/index.php/fm/article/view/11095) **First Monday** 2020.
- [User Concerns & Tradeoffs in Technology-facilitated COVID-19 Response.](https://dl.acm.org/doi/10.1145/3428093) **ACM Digital Government**: Research and Practice (DGOV) 2020.
- [How good is good enough for COVID19 apps? The influence of benefits, accuracy, and privacy on willingness to adopt.](https://arxiv.org/abs/2005.04343) ARXIV 2020.
    - [Slides](${process.env.PUBLIC_URL}/research/how-good-is-good-enough.pdf)
`

const talks = 
`
- [Coronavirus Apps Show Promise but Prove a Tough Sell](https://www.nytimes.com/2020/12/07/technology/coronavirus-exposure-alert-apps.html) **The New York Times** 2020.
- [People Proving to Be Weakest Link for Apps Tracking COVID Exposure](https://khn.org/news/people-proving-to-be-weakest-link-for-apps-tracking-covid-exposure/) **Kaiser Health News** 2020.
- [The Biggest Bug For Coronavirus Exposure Apps? The People Using Them](https://www.cpr.org/2020/11/19/the-biggest-bug-for-coronavirus-exposure-apps-the-people-using-them/) **Colorado Public Radio** 2020.
- [Keynote: Learning from the People: Responsibly Encouraging Adoption of Contact Tracing Apps]() **Amsterdam Data Science** 2020.
- [Keynote: Learning from the People: Responsibly Encouraging Adoption of Contact Tracing Apps]() **New Jersey Big Data Association** 2020.
- [Plenary Talk: User Tradeoffs in the Adoption of COVID19 Apps](https://responsibledata.ai/pandemic) **Responsible Data Summit** 2020.
- [Invited Lecture: Learning from the People: Responsibly Encouraging Adoption of Contact Tracing Apps](https://informatics.tuwien.ac.at/news/1937) **Digital Humanism Lecture Series** 2020.
- [Plenary Talk: Responsibly Encouraging Adoption of Contact Tracing Apps: How Good is Good Enough?](https://ml.ucsb.edu/2020-responsible-machine-learning-summit) **Responsible Machine Learning Summit** 2020.
- [Plenary Talk: UX & Adoption Challenges for COVID19 contact tracing apps](https://events.linuxfoundation.org/gaen-symposium/attend/about/) **Linux Foundation GAEN Symposium** 2020.
- [Invited Panel Talk: Moving Beyond the Consent or Anonymise Dichotomy](http://ipdlnconference.org/?page_id=1466) **International Population Data Linkage Conference** 2020.
- [Podcast Interview: Diversity in Contact Tracing](https://thecyberwire.com/podcasts/caveat/39/notes) **The Cyberwire** 2020.
`
const collaborators = `
- [Dan Goldstein](https://www.microsoft.com/en-us/research/people/dgg/), Microsoft Research
- [Eszter Hargittai](http://www.eszter.com/), University of Zurich
- [Jake Hofman](https://www.microsoft.com/en-us/research/people/jmh/), Microsoft Research
- [Gabriel Kaptchuk](https://cs-people.bu.edu/kaptchuk/), Boston University
- [John Krumm](https://www.microsoft.com/en-us/research/people/jckrumm/), Microsoft Research
- [Dana Turjeman](https://sites.google.com/umich.edu/turji), University of Michigan
`
const Research: Array<CovidResearch> = [
  {
    title:  'Research Output',
    markdown: researchoutput,
  },
    {
    title:  'Talks & Media Coverage',
    markdown: talks,
  },
  {
    title:  'Collaborators',
    markdown: collaborators,
  },
];

export default Research;

export {
  mapStringToUri,
  Research,
}
