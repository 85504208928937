// @flow strict-local
import * as React from 'react';
import PublicationBestPaper from './PublicationBestPaper.react';
import PublicationBestPaperHonorableMention from './PublicationBestPaperHonorableMention.react';
import PublicationBestPaperRunnerUp from './PublicationBestPaperRunnerUp.react';
import PublicationBestPresentation from './PublicationBestPresentation.react';
import PublicationDiversity from './PublicationDiversity.react';
import PublicationNewHorizon from './PublicationNewHorizon.react';

import {getAcceptanceText} from './PublicationUtils';
import type {AcceptanceRate, Year} from '../data/GenericTypes';

import './PublicationConference.css';


type Props = {
  acceptanceRate: AcceptanceRate,
  bestPaper?: ?boolean,
  bestPaperRunnerUp?: ?boolean,
  bestPaperHonorableMention?: ?boolean,
  bestPresentation?: ?boolean,
  diversity?: ?boolean,
  newHorizon?: ?boolean,
  conference: string,
  year: Year,
}


type ConferenceProps = {|
  className?: string,
  conference: string,
  year: Year | Array<Year>,
|}

const Conference = (props: ConferenceProps): React.Node => {
  let years = '';
  if (typeof props.year === 'number') {
    years = props.year.toString();
  } else if (typeof props.year) {
    props.year.forEach(year => {
      years += years === '' ? '' : ', ';
      years += year.toString();
    });
  }

  return (
    <div className={props.className}>
      <span className="PublicationConferenceName">
        {props.conference}
      </span>
      <span className="PublicationConferenceYear PublicationConferenceLightText">
        {years}
      </span>
    </div>
  );
}

const PublicationConference = (props: Props): React.Node => {
  return (
    <div className="PublicationConference">
      <Conference
        conference={props.conference}
        year={props.year}
      />
      <div className="PublicationSecondaryRows PublicationConferenceLightText">
        {getAcceptanceText(props.acceptanceRate)}
      </div>
      <PublicationBestPaper bestPaper={props.bestPaper} />
            <PublicationBestPaperRunnerUp bestPaperRunnerUp={props.bestPaperRunnerUp} />
               <PublicationBestPaperHonorableMention bestPaperHonorableMention={props.bestPaperHonorableMention} />
      <PublicationBestPresentation bestPresentation={props.bestPresentation} />
      <PublicationDiversity diversity={props.diversity} />
      <PublicationNewHorizon newHorizon={props.newHorizon} />
    </div>
  );
}

export {Conference, PublicationConference};
export default PublicationConference;
