// @flow
import './Glyph.css';
import * as React from 'react';

export type GlyphSize =
  'xs' |	   // .75em
  'sm'	|    // .875em
  'lg'	|    // 1.33em	Also applies vertical-align: -25%
  '2x'	|    // 2em
  '3x'	|    // 3em
  '4x'	|    // 4em
  '5x'	|    // 5em
  '6x'	|    // 6em
  '7x'	|    // 7em
  '8x' |    //	8em
  '9x'	|    // 9em
  '10x';    //	10em

export type GlyphName =
  'award' |
  'blog' |
  'broadcast' |
  'download' |
  'external-link' |
  'github' |
  'home' |
  'landmark' |
  'linkedin' |
  'minus-square' |
  'pdf' |
  'plus-square' |
  'question-circle' |
  'trophy' |
  'twitter' |
  'user' |
  'video';

type Props = {|
  children?: React.Node,
  link?: ?string,
  glyph: GlyphName,
  downloadName?: ?string,
  hidden: boolean,
  fixedWidth: boolean,
  size?: GlyphSize,
|}

const getGlyphSizeClass = (size: ?GlyphSize): string => {
  if (size == null) {
    return '';
  }

  return `fa-${size}`;
}

const getGlyphClass = (glyph: GlyphName): string => {
  switch (glyph) {
     case 'award':
      return 'fas fa-award';
    case 'blog':
      return 'fab fa-blogger';
//      return 'fa fa-book';

    case 'broadcast':
      return 'fas fa-broadcast-tower';

    case 'download':
      return 'fas fa-download';

    case 'external-link':
      return 'fas fa-external-link-alt';

    case 'github':
      return 'fab fa-github';

    case 'home':
      return 'fas fa-home'

    case 'landmark':
      return 'fas fa-landmark ';

    case 'linkedin':
      return 'fab fa-linkedin';

    case 'minus-square':
      return 'fas fa-minus-square';

    case 'pdf':
      return 'fas fa-file-pdf';

    case 'plus-square':
      return 'fas fa-plus-square';

    case 'question-circle':
      return 'far fa-question-circle'

    case 'trophy':
      return 'fas fa-trophy';

    case 'twitter':
      return 'fab fa-twitter-square';

    case 'user':
      return 'fas fa-user-friends';

    case 'video':
      return 'fas fa-video';

    default:
      throw new Error('Unknown icon: ' + glyph);
  }
}

const Glyph = (props: Props): React.Node => {
  let glyphClass = getGlyphClass(props.glyph);
  glyphClass += " " + getGlyphSizeClass(props.size);
  glyphClass += props.fixedWidth ? " fa-fw" : '';
  const glyph = <i className={glyphClass} aria-hidden="true" />;

  let className = "Glyph";
  className += props.hidden ? ' Hidden' : '';


  if (props.link == null) {
    return <span className={className}>{glyph}</span>;
  }

  return (
    <a
      className={className}
      href={props.link}
      target="_blank"
      download={props.downloadName}
      rel="noopener noreferrer">
      {props.children}
      {glyph}
    </a>
  );
};


Glyph.defaultProps = {
  hidden: false,
  fixedWidth: false,
}


export default Glyph;
