// @flow strict-local
import * as React from 'react';
import PublicationSecondaryRow from '../PublicationSecondaryRow.react';
import './PublicationMedia.css';
import type {
  PublicationMedia as PublicationMediaData,
} from '../../data/PublicationData';

type Props = {
  media: ?Array<PublicationMediaData>,
}

const renderMedia = (media: PublicationMediaData, idx: number): React.Node => {
  const title_length = Math.max(50 - media.publisher.length, 20);
  let title = media.title.substring(0, title_length);
  if (media.title.length > 30) {
    title = title.trim() + '...';
  }

  return (
    <div className="PublicationMedia" key={idx}>
      <span className="PublicationMediaPublisher">
        {media.publisher}
      </span>
      <span className="PublicationMediaTitle">
        <a href={media.uri} >
          {title}
        </a>
      </span>
    </div>
  );
}

const PublicationMedia = (props: Props): React.Node => {
  const media = props.media;
  if (media == null || media.length === 0) {
    return null;
  }

  return (
    <PublicationSecondaryRow glyph="broadcast" >
      <div style={{'color': '#aaa'}}>
        Media
      </div>
      <div style={{'marginLeft': '8px'}}>
        {media.map(renderMedia)}
      </div>
    </PublicationSecondaryRow>
  );
}

export default PublicationMedia;
