// @flow strict-local
import * as React from 'react';
import Media from '../core/Media.react';
import type {AcceptanceRate} from '../data/GenericTypes';
import {TBD, WORKSHOP, JOURNAL, CHAPTER,ARXIV,DISSERTATION} from '../data/GenericTypes';

const getAcceptanceText = (rate: AcceptanceRate): string => {
  if (rate === WORKSHOP) {
    return WORKSHOP;

  } else if (rate === JOURNAL) {
    return JOURNAL;
  } else if (rate === CHAPTER) {
    return CHAPTER;
  } else if (rate === ARXIV) {
    return ARXIV;
  } else if (rate === DISSERTATION) {
    return DISSERTATION;
  }

  const percentage = rate === TBD
    ? TBD
    : rate.toString() + '%';

  return 'Acceptance: ' + percentage;
}

const MobileOnly = (props: {children: React.Node}): React.Node => (
  <Media query="only screen and (max-width: 800px)">
    {props.children}
  </Media>
)

const NonMobileOnly = (props: {children: React.Node}): React.Node => (
  <Media query="not screen and (max-width: 800px)">
    {props.children}
  </Media>
)

export {
  getAcceptanceText,
  MobileOnly,
  NonMobileOnly,
};
