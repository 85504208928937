// @flow
import * as React from 'react';
import {Page} from 'react-pdf';
import './CVPageViewer.css'

type Props = {
  documentWidth: number,
  pageNumber: number,
}
type State = {|
  pageWidth: ?number,
|};

class CVPageViewer extends React.Component<Props, State> {
  state: State = {
    pageWidth: null,
  }

  _onLoadSuccess = (page: {originalWidth: number}) => {
    this.setState({
      pageWidth: page.originalWidth,
    });
  }

  render(): React.Node {
    const pageWidth = this.state.pageWidth;
    let scale = undefined;
    if (pageWidth != null) {
      scale = this.props.documentWidth / pageWidth;
      // round scale to two decimal places.
      scale = Math.floor(scale * 100) / 100;
      // Subtract an extra .02 to ensure we cannot scroll sideways.
      scale -= .00;
      // if 'scale' is greater than 1, page is wider tha PDF.  No need to
      // to zoom in on the page.
      scale = (scale > 1.0) ? undefined : scale;
    }

    return (
      <div className="CVPageViewer">
        <Page
          className="CVPageViewerPage"
          pageNumber={this.props.pageNumber}
          scale={scale}
          onLoadSuccess={this._onLoadSuccess}
          renderAnnotations={false}
        />
      </div>
    );
  }
}

export default CVPageViewer;
