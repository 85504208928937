// @flow strict-local
import './PublicationSecondaryRow.css';
import Glyph from '../core/Glyph.react';
import * as React from 'react';

import type {GlyphName} from '../core/Glyph.react';

type Props = {
  children: React.Node,
  className?: ?string,
  glyph: GlyphName,
}

const PublicationSecondaryRow = (props: Props): React.Node => {
  let className = "PublicationSecondaryRow";
  className += props.className != null ? ' ' + props.className : '';

  return (
    <div className={className}>
      <div className="PublicationGlyph">
        <Glyph glyph={props.glyph} fixedWidth size="sm" />
      </div>
      <div className="PubliationContent">
        {props.children}
      </div>
    </div>
  );
}

export default PublicationSecondaryRow;
