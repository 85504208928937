// @flow strict-local
import * as React from 'react';
import Glyph from '../core/Glyph.react';
import './Award.css';

import type {AwardData} from '../data/AwardData';

const renderDetails = (details?: ?string): ?React.Node => {
  if (details == null) {
    return null;
  }

  return (
    <div className="AwardDetails">
      {details}
    </div>
  );
}

const renderLink = (link?: ?string): ?React.Node => {
  return (
    <Glyph
      glyph="question-circle"
      link={link}
      hidden={link == null}
    />
  );
}


const Award = (props: AwardData) => (
  <div className="Award">
    <div className="AwardTitle">
      <div className="AwardTitleText">
        {props.title}
      </div>
      <div className="AwardTitleLink">
        {renderLink(props.link)}
      </div>
    </div>
    {renderDetails(props.note)}
  </div>
);

export default Award;
