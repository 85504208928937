// @flow

import type {Node} from 'react';
import type {IntimacyResearch} from '../data/IntimacyResearch.js';

import React, {useState} from 'react';
import Section from '../core/Section.react';
import ReactMarkdown from 'react-markdown';
import Glyph from '../core/Glyph.react';

import {Research, mapStringToUri} from '../data/IntimacyResearch';

import './Intimacy.css';

function IntimacyHeader(props: {}): Node {
  return (
    <div className="IntimacyHeader">
      <Glyph
        glyph="external-link"
        link="https://www.safedigitalintimacy.org"
        >
        <span style={{marginRight: '4px'}}>safedigitalintimacy.org</span>
      </Glyph>
    </div>
  );
}

function IntimacyResearchSection(props: {}): Node {

  return (
    <Section
      label="Digital Intimacy Research"
      className="Intimacy" 
      hideLabel={true}
      displayIframe={true}>
      <IntimacyHeader />
      <iframe src="https://www.safedigitalintimacy.org" style={{width: '100%', height: '78vh'}} />
    </Section>
  );
};

export default IntimacyResearchSection;
