// @flow
import * as React from 'react';
import PublicationConference from './PublicationConference.react';
import PublicationMainColumn from './PublicationMainColumn.react';
import {NonMobileOnly} from './PublicationUtils';

import './Publication.css';
import type {PublicationData} from '../data/PublicationData';


type Props = {
  publication: PublicationData,
  publicationNumber: number,
}

const Publication = (props: Props): React.Node => {
  const publication = props.publication;
  const {year, conference} = props.publication;

  return (
    <div className="Publication">
      <div className="PublicationNumber" >
        {(props.publicationNumber).toString() + '.'}
      </div>
      <div className="PublicationCenterColumn">
        <PublicationMainColumn {...props.publication} />
      </div>
      <NonMobileOnly>
        <div className="PublicationRightColumn">
          <PublicationConference
            conference={conference}
            acceptanceRate={publication.acceptanceRate}
            year={year}
            bestPaper={publication.bestPaper}
            bestPaperRunnerUp={publication.bestPaperRunnerUp}
            bestPaperHonorableMention={publication.bestPaperHonorableMention}
            bestPresentation={publication.bestPresentation}
            diversity={publication.diversity}
            newHorizon={publication.newHorizon}
          />
        </div>
      </NonMobileOnly>
    </div>
  );
}

export default Publication;
