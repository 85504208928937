// @flow strict-local
import * as React from 'react';
import Section from '../core/Section.react';
import Video from '../core/Video.react';
import './About.css';

const About = (): React.Node => (
  <Section label="About" className="About">
      <div className="AboutText">
        <p>
My research interests are broadly in the areas of security and privacy. I use computational, economic, and social science methods to understand & model users’ safety decision-making processes. I specifically investigate inequities that arise in these processes and work directly with marginalized and at-risk communities to design systems that facilitate safety equitably and respectfully.
        </p>
        <p>
          Want a brief sample of my work?
          Check out 
          <a
            className="embedly-card"
            style={{marginLeft: '4px', marginRight: '4px'}}
            href="https://www.scientificamerican.com/author/elissa-redmiles/">
             my recent essays in Scientific American 
          </a>
          and a talk I gave about using human inputs to improve safety in digital systems.
        </p>
        <div style={{display: "flex", justifyContent: "center", paddingTop: "8px"}} >
          <div style={{flex: "0 0 60%", }}>
            <Video video={{
              source: "Youtube",
              uri: "https://www.youtube-nocookie.com/embed/buM9DAkTpko?start=5",
              config: {
                ratio: '16:9',
                title: 'Why You Should Use Human Inputs in Computation: ' +
                  'Examples from Security, Privacy and Machine Learning',
              },
            }} />
          </div>
        </div>
      </div>
  </Section>
);

export default About;
