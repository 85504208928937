// @flow strict-local
import * as React from 'react';
import './ServiceCard.css';

import BasicSection from './ServiceBasicSection.react';
import DiversityAndInclusion from './ServiceDiversityAndInclusion.react';

import {
  CONFERENCE_ORGANIZING,
  PC_MEMBERSHIPS,
} from '../data/ServiceData';

const ServiceCard = (): React.Node => (
  <div className="ServiceCard">
    <BasicSection
      label="Conference and Journal Organization"
      items={CONFERENCE_ORGANIZING}
    />
    <BasicSection
      label="Program Committees"
      items={PC_MEMBERSHIPS}
    />
    <DiversityAndInclusion />
  </div>
);

export default ServiceCard;
