// @flow strict-local
// $FlowFixMe
import ReactMarkdown from 'react-markdown';
import * as React from 'react';
import {
  MobileOnly,
  NonMobileOnly,
} from './PublicationUtils';

import PublicationAuthors from './view/PublicationAuthors.react';
import PublicationDownload from './view/PublicationDownload.react';
import PublicationMedia from './view/PublicationMedia.react';
import PublicationWatchPresentation from './view/PublicationWatchPresentation.react';

import MobilePublicationConference from './view_mobile/MobilePublicationConference.react';

import PublicationBestPaper from './PublicationBestPaper.react';
import PublicationBestPaperRunnerUp from './PublicationBestPaperRunnerUp.react';
import PublicationBestPresentation from './PublicationBestPresentation.react';

import './PublicationMainColumn.css';
import type {PublicationData} from '../data/PublicationData';


const SecondaryColumns = (props: PublicationData): React.Node => (
  <NonMobileOnly>
    <PublicationAuthors authors={props.authors} note={props.authorsNote} />
    <PublicationMedia media={props.articles} />
    <PublicationDownload uri={props.uri} />
    {/* <PublicationWatchPresentation presentation={props.presentation} /> */}
  </NonMobileOnly>
);

const MobileSecondaryColumns = (props: PublicationData): React.Node => (
  <MobileOnly>
    <PublicationAuthors authors={props.authors} note={props.authorsNote} />
    <MobilePublicationConference
      conference={props.conference}
      acceptanceRate={props.acceptanceRate}
      year={props.year}
    />
    <PublicationMedia media={props.articles} />
    <PublicationDownload uri={props.uri} />
    <PublicationBestPaper bestPaper={props.bestPaper} />
     <PublicationBestPaperRunnerUp bestPaperRunnerUp={props.bestPaperRunnerUp} />
    <PublicationBestPresentation bestPresentation={props.bestPresentation} />
    {/* <PublicationWatchPresentation presentation={props.presentation} /> */}
  </MobileOnly>
);


const PublicationMainColumn = (props: PublicationData): React.Node => {
  return (
    <div className="PublicationMainColumn">
      <div className="PublicationTitle">
        <ReactMarkdown>
          {props.title}
        </ReactMarkdown>
      </div>
      <div className="PublicationSecondaryRows" style={{marginLeft: '12px'}}>
        <SecondaryColumns {...props} />
        <MobileSecondaryColumns {...props} />
      </div>
    </div>
  );
}

export default PublicationMainColumn;
