import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import {
  RouterProvider,
} from "react-router-dom";


const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<RouterProvider router={App} />);
