// @flow strict-local
import * as React from 'react';
import './ServiceRow.css';
import {Conference} from '../publications/PublicationConference.react';
import type {Service} from '../data/ServiceData';

const ServiceRow = (props: Service): React.Node => {
  return (
    <div className="ServiceRow">
      <Conference
        className="ServiceRowName"
        conference={props.conference}
        year={props.year}
      />
      <div className="ServiceRowPosition">
        {props.servicePosition}
      </div>
    </div>
  );
}

export default ServiceRow;
