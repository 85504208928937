// @flow strict-local
import type {Year} from './GenericTypes';
export type Service = {|
  conference: string,
  servicePosition?: ?string,
  year: Year | Array<Year>,
|}

const CONFERENCE_ORGANIZING = [
      {
    conference: 'ACM EAAMO',
    servicePosition: 'PC Co-Chair',
    year: [2023],
  },
    {
    conference: 'ACM CCS',
    servicePosition: 'Usability & Measurement Track Chair',
    year: [2023],
  },
      {
    conference: 'IEEE ConPro Workshop',
    servicePosition: 'General Co-Chair',
    year: [2023],
  },
    {
    conference: 'IEEE ConPro Workshop',
    servicePosition: 'PC Co-Chair',
    year: [2022],
  },
  {
    conference: 'IEEE Security & Privacy',
    servicePosition: 'Research Ethics Committee Member',
    year: [2022, 2023],
  },
 {
    conference: 'IEEE Security & Privacy Magazine',
    servicePosition: 'Editorial Board Member & Social Media Editor',
    year: [2021, 2022, 2023],
  },
  {
    conference: 'ACM Digital Threats: Research and Practice',
    servicePosition: 'Guest Editor, Special Issue on Security and Privacy for Covid-19',
    year: [2020],
  },
  {
    conference: 'ACM Computer & Communications Security (CCS)',
    servicePosition: 'Student Grants Co-Chair',
    year: [2019],
  },
  {
    conference: 'USENIX SOUPS',
    servicePosition: 'Workshops Co-Chair',
    year: [2018, 2019],
  },
  {
    conference: 'USENIX Security',
    servicePosition: 'Invited Talks Committee',
    year: [2019],
  },
  {
    conference: 'EuroUSEC',
    servicePosition: 'Publicity Chair',
    year: [2018, 2019],
  },
];

const PC_MEMBERSHIPS = [
    {
    conference: 'IEEE Security & Privacy',
    servicePosition: 'Associate Chair',
    year: [2023],
  },
      {
    conference: 'IEEE SaTML',
    year: [2023],
  },
   {
    conference: 'IEEE Security & Privacy',
    year: [2020, 2022],
  },
  {
    conference: 'USENIX Security',
    year: [2019, 2020, 2021, 2022],
  },
     {
    conference: 'USENIX Annual Technical Conference (ATC)',
    year: [2022],
  },
       {
    conference: 'ACM Computer and Communications Security (CCS)',
    year: [2022],
  },
    {
    conference: 'ACM Economics and Computation (EC)',
    year: [2020, 2022],
  },
         {
    conference: 'International Symposium on Research in Attacks, Intrusions and Defenses (RAID)',
    year: [2022],
  },
    {conference: 'Journal of Quantitative Description: Digital Media',
  servicePosition: 'Editorial Board',
  year:[2021],
  },
  {
    conference: 'PoPETS',
    servicePosition: 'Editorial Board',
    year: [2021],
  },
  {
    conference: 'IEEE ConPro Workshop',
    year: [2019,2020, 2021],
  },
  {
    conference: 'ACM CSCW',
    servicePosition: 'Associate Chair',
    year: [2020],
  },
  {
    conference: 'AAAI ICWSM',
    servicePosition: 'Senior PC',
    year: [2020],
  },
  {
    conference: 'eCrime',
    year: [2019, 2020],
  },
   {
    conference: 'AAAI Emerging Track on AI for Social Impact',
    year: [2020],
  },
   {
    conference: 'WEIS',
    year: [2020],
  },
  {
    conference: 'AAAI ICWSM',
    year: [2019],
  },
  {
    conference: 'ACM SACMAT',
    year: [2019],
  },
  {
    conference: 'ACM SIGSCE',
    year: [2019],
  },
  {
    conference: 'USENIX CSET',
    year: [2019],
  },
  {
    conference: 'USEC',
    year: [2019],
  },
  {
    conference: 'EuroUSEC',
    year: [2017, 2018, 2019],
  },
  {
    conference: 'ACM CHI Networked Privacy Workshop',
    year: [2018],
  },
];

/*const EXTERNAL_REVIEWS = [
  {
    conference: 'ACM SIGHI',
    year: [2016, 2017, 2018, 2019, 2020],
  },
  {
    conference: 'ACM IMWUT',
    year: [2017, 2018],
  },
  {
    conference: 'ACM CSCW',
    year: [2018],
  },
  {
    conference: 'ACM TOCHI',
    year: [2018],
  },
  {
    conference: 'ACM TOPS',
    year: [2018],
  },
  {
    conference: 'ACM TWEB',
    year: [2018],
  },
  {
    conference: 'PETS',
    year: [2018],
  },
  {
    conference: 'Computers & Security',
    year: [2019],
  },
];*/

const CODE_GIRLS_LINK = 'http://www.cs.umd.edu/article/2017/01/ncwit-and-google-fund-department-program-teaching-young-women-code';
const BOX_LINK = 'http://csinabox.cs.umd.edu/';
const CURRICULUM_LINK = 'https://dl.acm.org/citation.cfm?id=2691870';
const I_LINK = 'http://www.i3-inclusion.org/';
const DMA_LINK = 'https://www.digitalmediaacademy.org/';

// NOTE:  Default bold, italic, strong, hr, etc values overwritten in
//   ServiceDiversityAndInclusion.css
const DIVERSITY_INCLUSION_OUTREACH_TEXT = `
* **PI** for Grants to develop CS education programs for underrepesented minority students & woman.
    *  [Curriculum In A Box](${BOX_LINK}) [SIGCSE2015](${CURRICULUM_LINK})
    *  [Code Girls: Get Paid to program](${CODE_GIRLS_LINK})
* Co-Chair Graduate Women in Computing @ UMD
* Research Advisor and Teaching Fellow [Information Inclusion Intitute](${I_LINK}) @ UPitt
* Diversity & Inclusion Consultant @ [Digital Media Academy](${DMA_LINK})
`

export {
  CONFERENCE_ORGANIZING,
  PC_MEMBERSHIPS,
  DIVERSITY_INCLUSION_OUTREACH_TEXT,
}
