// @flow
import * as React from 'react';
import Glyph from '../core/Glyph.react';

import './PublicationNewHorizon.css';

type Props = {
  newHorizon: ?boolean,
}

const PublicationNewHorizon = (props: Props): React.Node => {
  const newHorizon = props.newHorizon;
  if (newHorizon !== true) {
    return null;
  }

  return (
    <div className="PublicationNewHorizon">
      <div className="PublicationNewHorizonTrophy" >
        <Glyph glyph="trophy" />
      </div>
      <div className="PublicationNewHorizonText" >
        New Horizons Award
      </div>
    </div>
  );
}

export default PublicationNewHorizon
