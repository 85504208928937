// @flow strict-local

import type {
  Source as ImportedVideoSource,
  VideoSettings as ImportedVideoSettings,
  FileType as ImportedVideoFileTypes,
} from '../core/Video.react';

const TBD = 'TBD';
const WORKSHOP = 'Workshop';
const JOURNAL = 'Journal';
const CHAPTER = 'Book Chapter';
const ARXIV = 'Not peer reviewed';
const DISSERTATION = 'Dissertation';

export type VideoFileTypes = ImportedVideoFileTypes;
export type VideoSource = ImportedVideoSource;
export type VideoSettings = ImportedVideoSettings;
export type Year = 2015 | 2016 | 2017 | 2018 | 2019 | 2020 | 2021 | 2022 | 2023 | 2024;
export type AcceptanceRate = number | 'Workshop' | 'TBD' | 'Journal' | 'Book Chapter' | 'Not peer reviewed' | 'Dissertation';

export {
  TBD,
  WORKSHOP,
  JOURNAL,
  CHAPTER,
  ARXIV,
  DISSERTATION,
}
