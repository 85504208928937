// @flow
import * as React from 'react';
import Glyph from '../core/Glyph.react';

import './PublicationBestPaper.css';

type Props = {
  bestPaper: ?boolean,
}

const PublicationBestPaper = (props: Props): React.Node => {
  const bestPaper = props.bestPaper;
  if (bestPaper !== true) {
    return null;
  }

  return (
    <div className="PublicationBestPaper">
      <div className="PublicationBestPaperTrophy" >
        <Glyph glyph="trophy" />
      </div>
      <div className="PublicationBestPaperText" >
        Distinguished Paper Award
      </div>
    </div>
  );
}

export default PublicationBestPaper
