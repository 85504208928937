// @flow
import './NavbarItem.css';
import Glyph from '../core/Glyph.react';

import * as React from 'react';
import {
  NavLink,
  useLocation,
} from 'react-router-dom';

type CoreProps = {
  children: React.Node,
  location: Object, // From ReactRouter's `withRouter` function
  path: string,
}

const NavbarCoreImpl = (props: CoreProps): React.Node => {
  const location = useLocation();

  // Appends the "Active" class to the active Navbar link
  const active_classname = location.pathname === props.path
   ? ' Active'
   : '';

  return (
    <NavLink
      to={props.path}
      className={'NavbarItemText' + active_classname}>
      <div className={'NavbarItem' + active_classname}>
        {props.children}
      </div>
    </NavLink>
  );
}
//const NavbarCore = withRouter(NavbarCoreImpl);
const NavbarCore = NavbarCoreImpl;


type TextProps = {
  label: string,
  path: string,
}

const NavbarTextItem = (props: TextProps): React.Node => (
  <NavbarCore path={props.path} >
    {props.label}
  </NavbarCore>
);

const NavbarGlyphItem = (props: TextProps): React.Node => (
    <NavbarCore path={props.path}>
      <div>
        <Glyph glyph="home" />
      </div>
    </NavbarCore>
);

export {NavbarGlyphItem, NavbarTextItem}

export default NavbarTextItem;
