// @flow strict-local
import * as React from 'react';
import Section from '../core/Section.react'
import Award from './Award.react';
import './Awards.css';
import {AWARDS} from '../data/AwardData';

import type {AwardData} from '../data/AwardData';

const renderAward = (award: AwardData, key: number): React.Node => (
  <Award
    key={key}
    {...award}
  />
);

const Awards = (): React.Node => (
  <Section label="Selected Awards" className="Awards">
    {AWARDS.map(renderAward)}
  </Section>
);

export default Awards;
