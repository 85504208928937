// @flow strict-local
import * as React from 'react';
import PublicationSecondaryRow from '../PublicationSecondaryRow.react';

type Props = {|
  uri: ?string,
|}

const PublicationDownload = (props: Props): React.Node => {
  const uri = props.uri;
  if (uri == null) {
    return null;
  }

  return (
    <PublicationSecondaryRow className="PublicationDownload" glyph="pdf">
      <a href={props.uri} >
        {'Download'}
      </a>
    </PublicationSecondaryRow>
  );
}

export default PublicationDownload
