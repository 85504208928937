// @flow

import * as React from 'react';
import Navbar from './navbar/Navbar.react';
import About from './main/About.react';
import CovidResearch from './covid/Covid.react';
import IntimacyResearch from './intimacy/Intimacy.react';
import CVCard from './cv/CVCard.react';
import Name from './main/Name.react';
import Awards from './main/Awards.react';
import Footer from './main/Footer.react';
import Service from './service/ServiceCard.react';
import PublicationsCard from './publications/PublicationsCard.react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet,
} from 'react-router-dom';

const Home = (): React.Node => (
  <div>
    <Name />
    <About />
    <Awards />
  </div>
);

/*
- Home
  *  Awards
- Publications
- Service
  *  Fellowships
  *  Grants
  *

- Survey Methodology
- CV

  <Router basename={'/elissaredmiles.com/index.html'}>
  <Router basename={process.env.NODE_ENV === 'production' ? '/elissaredmiles.com' : '/'}>

*/

const BasicExample = () => (
  // TODO:  setup error page route.
  <Router basename={'/'}>
    <div>
      <Navbar />
      <Route exact path="/" component={Home}/>
      <Route path="/publications" component={PublicationsCard}/>
      <Route path="/service" component={Service} />
      <Route path="/cv" component={CVCard} />
      <Route path="/covid" component={CovidResearch} />
      <Route path="/intimacy" component={IntimacyResearch} />
      <Footer />
    </div>
  </Router>
)

const Root = () => (
  <div>
    <Navbar />
      <Outlet />
    <Footer />
  </div>
);

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route path="/" element={<Home />}/>
      <Route path="publications" element={<PublicationsCard />}/>
      <Route path="service" element={<Service />} />
      <Route path="cv" element={<CVCard />} />
      <Route path="covid" element={<CovidResearch />} />
      <Route path="intimacy" element={<IntimacyResearch />} />
    </Route>
  )
);

export default Router;
