// @flow
import * as React from 'react';
import './CVHeader.css';
import Glyph from '../core/Glyph.react';

const CVHeader = () => (
  <div className="CVHeader">
    <Glyph
      glyph="external-link"
      link={(process.env.PUBLIC_URL || '') + '/cv-eredmiles.pdf'}
    >
      <span style={{marginRight: '4px'}}>Download</span>
    </Glyph>
  </div>
);

export default CVHeader;
