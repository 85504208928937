// @flow strict-local
import * as React from 'react';
import Section from '../core/Section.react';
import ServiceRow from './ServiceRow.react';

import type {Service} from '../data/ServiceData';
import './ServiceBasicSection.css';

type Props = {|
  label: string,
  items: Array<Service>,
|}

const renderService = (row: Service, index: number): React.Node => (
  <ServiceRow
    key={index}
    {...row}
  />
);

const ServiceBasicSection = (props: Props): React.Node => (
  <Section
    className="ServiceBasicSection"
    label={props.label}>
    <div className="ServiceBasicSectionContent">
      {props.items.map(renderService)}
    </div>
  </Section>
);

export default ServiceBasicSection;
