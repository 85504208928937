// @flow strict-local
import './Navbar.css';
import * as React from 'react';
import {
  NavbarTextItem as NavbarItem,
} from './NavbarItem.react';

const Navbar = (): React.Node => (
  <header>
    <div className="Navbar">
      <NavbarItem label="About" path="/" />
      <NavbarItem label="Publications" path="/publications" />
      <NavbarItem label="Service" path="/service" />
      <NavbarItem label="Digital Intimacy Research" path="/intimacy" />
      <NavbarItem label="COVID19 Research" path="/covid" />
      {/*<NavbarItem label="Survey Methodology" path="/survey_methodology" />*/}
      <NavbarItem label="CV" path="/cv" />
    </div>
  </header>
);

export default Navbar;
