// @flow
import * as React from 'react';
import Glyph from '../core/Glyph.react';

import './PublicationBestPaperHonorableMention.css';

type Props = {
  bestPaperHonorableMention: ?boolean,
}

const PublicationBestPaperHonorableMention = (props: Props): React.Node => {
  const bestPaperHonorableMention = props.bestPaperHonorableMention;
  if (bestPaperHonorableMention !== true) {
    return null;
  }

  return (
    <div className="PublicationBestPaperHonorableMention">
      <div className="PublicationBestPaperHonorableMentionTrophy" >
        <Glyph glyph="trophy" />
      </div>
      <div className="PublicationBestPaperHonorableMentionText" >
        Honorable Mention
      </div>
    </div>
  );
}

export default PublicationBestPaperHonorableMention
