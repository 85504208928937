// @flow strict-local
import * as React from 'react';
import PublicationSecondaryRow from '../PublicationSecondaryRow.react';

import './MobilePublicationConference.css';

import {getAcceptanceText} from '../PublicationUtils';
import type {AcceptanceRate, Year} from '../../data/GenericTypes';

type Props = {|
  acceptanceRate: AcceptanceRate,
  conference: string,
  year: Year,
|}

const MobilePublicationConference = (props: Props): React.Node => (
  <PublicationSecondaryRow glyph="landmark">
    <div className="MobilePublicationConference">
      <div className="MobilePublicationConferenceName">
        {props.conference}
      </div>
      <div>
        ,
      </div>
      <div style={{marginLeft: '4px'}}>
        {props.year}
      </div>
      <div>
        .
      </div>
      <div style={{marginLeft: '8px'}}>
        {getAcceptanceText(props.acceptanceRate)}
      </div>
    </div>
  </PublicationSecondaryRow>
);

export default MobilePublicationConference
