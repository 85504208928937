// @flow
import * as React from 'react';
import Glyph from '../core/Glyph.react';

import './PublicationBestPresentation.css';

type Props = {
  bestPresentation: ?boolean,
}

const PublicationBestPresentation = (props: Props): React.Node => {
  const bestPresentation = props.bestPresentation;
  if (bestPresentation !== true) {
    return null;
  }

  return (
    <div className="PublicationBestPresentation">
      <div className="PublicationBestPresentationTrophy" >
        <Glyph glyph="award" />
      </div>
      <div className="PublicationBestPresentationText" >
        Best Presentation Runner Up
      </div>
    </div>
  );
}


export default PublicationBestPresentation
