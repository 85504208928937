// @flow strict-local
import * as React from 'react';
import Section from '../core/Section.react';
import {PUBLICATIONS} from '../data/PublicationData';
import Publication from './Publication.react';

import type {PublicationData} from '../data/PublicationData';


const renderPublication = (publication: PublicationData, count: number): React.Node => (
  <Publication
    key={count}
    publication={publication}
    publicationNumber={PUBLICATIONS.length - count}
  />
);

const PublicationsCard = (): React.Node => {
  return (
    <Section label="Publications" className="Publications">
      {PUBLICATIONS.map(renderPublication)}
    </Section>
  );
}

export default PublicationsCard;
