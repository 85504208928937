// @flow strict-local
import * as React from 'react';

import Section from '../core/Section.react';
import CVHeader from './CVHeader.react.js';
import CVViewer from './CVViewer.react.js';

const CVCard = () => (
  <Section
    className="CVCard"
    label="CVCard"
    hideLabel={true}
    displayIframe={true}>
    <CVHeader />
    <CVViewer />
  </Section>
);

export default CVCard;
