// @flow strict-local
// $FlowFixMe
import MediaQuery from "react-media";
import * as React from 'react';

type Props = {
  query: string,
  children: React.Node,
}

class Media extends React.Component<Props> {
  _renderContent = (): React.Node => {
    return this.props.children;
  }

  render(): React.Node {
    return (
      <MediaQuery
        query={this.props.query}
        render={this._renderContent}
      />
    );
  }
}

export default Media
