// @flow strict-local
import * as React from 'react';
import './Name.css';


const Name = (): React.Node => (
    <div className="Name">
      <div className="NameHeadshot" >
        <div className="NameVerticallyCenter" >
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/2017headshot.jpeg`}
            alt="2017 Headshot of Elissa"
            className="NameHeadshotImage"
          />
        </div>
      </div>
      <div className="NameContent">
        <div className="NameVerticallyCenter" >
          <div className="NameContentWrapper">
            <div className="NameContentFullName" >
              <h1>Elissa M. Redmiles</h1>
              <h3> Georgetown University </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
);

export default Name;
