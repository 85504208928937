// @flow strict-local

import type {Node} from 'react';


import React, {useState, useRef} from 'react';
import PublicationSecondaryRow from '../PublicationSecondaryRow.react';
import Video from '../../core/Video.react';

// $FlowFixMe
import Modal from 'react-responsive-modal';

import './PublicationWatchPresentation.css';

import type {VideoSettings} from '../../data/GenericTypes';


type Props = {|
  presentation: ?VideoSettings,
|}

function PublicationWatchPresentation(props: Props): Node {
  const [displayVideo, setDisplayVideo] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const presentationEl = useRef(null);

  const presentation = props.presentation;
  if (presentation == null) {
    return null;
  }

  let className = 'PublicationWatchPresentation';
  className += onMouseOver
    ? " PublicationWatchPresentationBorder"
    : "";

  const divWidth = presentationEl != null
  && presentationEl.current
  && presentationEl.current.offsetWidth;

  const width = (typeof divWidth == 'number')
    ? divWidth * 3
    : 720;
  
  console.log(`Set Display video:  ${displayVideo}`);

  return (
    <PublicationSecondaryRow glyph="video">
      <div className="PublicationPresentation">
        <div
          ref={presentationEl}
          className={className}
          onFocus={() => setOnMouseOver(true)}
          onMouseOver={() => setOnMouseOver(true)}
          onTouchStart={() => setOnMouseOver(true)}
          onMouseOut={() => setOnMouseOver(false)}
          onTouchEnd={() => setOnMouseOver(false)}
          onTouchCancel={() => setOnMouseOver(false)}
          onBlur={() => setDisplayVideo(false)}
          onClick={() => setDisplayVideo(true)}>
          <Video video={presentation} />
        </div>
        <WatchPresentationText isFocused={onMouseOver} />
        <Modal open={displayVideo} onClose={() => setDisplayVideo(false)} center focusTrapped={false}>
          {/* <div style={{'width': width.toString() + 'px'}} > */}
            <Video video={presentation} staticVideoWidth />
          {/* </div> */}
        </Modal>
      </div>
    </PublicationSecondaryRow>
  );

}


function WatchPresentationText(props: {isFocused: boolean}): Node {
  if (props.isFocused === false) {
    return null;
  }

  return (
    <div className="PublicationPresentationVideoText" >
        Watch Presentation
    </div>
  );
}


export default PublicationWatchPresentation;
export {
  PublicationWatchPresentation,
  WatchPresentationText,
};
