// @flow strict-local
import * as React from 'react';
import './Footer.css';
import Glyph from '../core/Glyph.react';
import Section from '../core/Section.react';

const FooterDataSection = (props: {label: string, text: Array<string>}): React.Node => (
  <div className="FooterDataSection">
    <div className="FooterDataSectionLabel">
      {props.label}
    </div>
    <div className="FooterDataSectionText">
      {props.text.map(e => <div key={e} >{e}</div>)}
    </div>
  </div>
);

const Footer = (): React.Node => (
  <div className="FooterRoot" >
    <Section label="Contact" hideLabel={true} className="Footer">
      <div className="FooterRow">
        <div className="FooterData">
          <FooterDataSection
            key="email"
            label="Email"
            text={[
              'elissa.redmiles AT georgetown DOT edu'
            ]}
          />
        </div>
        <div className="FooterLinks">
          <Glyph glyph="twitter" link="https://twitter.com/eredmil1" />
          <Glyph glyph="linkedin" link="http://www.linkedin.com/in/elissaredmiles" />
          <Glyph glyph="github" link="https://github.com/eredmiles" />
          <Glyph glyph="blog" link="https://thequestioningdeveloper.wordpress.com" />
        </div>
      </div>
    </Section>
  </div>
);

export default Footer;
