// @flow
import './Video.css';
import * as React from 'react';

// NOTE: Instead of support too many more sources, simply use
///
//    import ReactPlayer from 'react-player
//
// ReactPlayer includes all available sources.
export type Source = 'Youtube' | 'Self';
export type AspectRatio = '16:9' | '4:3';
export type FileType = 'video/mp4';

type YoutubeConfig = {
  ratio: AspectRatio,
  title: string,
}

type SelfConfig = {
  type: FileType,
}

export type VideoSettings = {
  source: 'Youtube',
  config: YoutubeConfig,
  uri: string,
} | {
  source: 'Self',
  config: SelfConfig,
  uri: string,
};

type Props = {
  video: VideoSettings,
}

const getAspectRatioClass = (ratio: AspectRatio): string => {
  switch(ratio) {
    case '16:9':
      return 'sixteen-nine';

    case '4:3':
      return 'four-three';

    default:
      throw new Error('Unknown ratio: ' + ratio);
  }
}

class Video extends React.Component<Props> {

  render(): React.Node {
    const video = this.props.video;
    switch(video.source) {
      case 'Youtube':
        const {ratio, title, ...other} = video.config;
        return (
          <div className={"YoutubeVideo " + getAspectRatioClass(ratio)}>
            <iframe
              {...other}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="allowFullScreen"
              src={video.uri}
              title={title}
              referrerPolicy="no-referrer"
            />
          </div>
        );

      case 'Self':
        return (
          <video controls width="100%" height="auto" >
            <source type={video.config.type} src={video.uri} />
          </video>
        );

      default:
        throw new Error('Unknown Source: ' + video.source);

    }
  }
}


// const Video = (props: Props): React.Node => (
//
// //  <iframe width="560" height="315" src="https://www.youtube.com/embed/9pKF6CTaPg4" </iframe>
//   <div className={"Video " + getAspectRatioClass(props.aspectRatio)}>
//     {getPlayer(props)}
//   </div>
// )

export default Video;
