// @flow
import * as React from 'react';

import {pdfjs, Document} from 'react-pdf';
import cv from './cv-eredmiles.pdf';
// import cv from '../../public/cv-eredmiles.pdf';
import CVDocumentViewer from './CVDocumentViewer.react';

// 'react-pdf' workaround.  For details, check out:
//   * problem: https://github.com/wojtekmaj/react-pdf#create-react-app
//   * solution: https://github.com/wojtekmaj/react-pdf#browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {}
type State = {|
  numPages: ?number,
  state: 'loading' | 'error' | 'success',
|};

class CVViewer extends React.Component<Props, State> {
  inputRef: ?HTMLDivElement;
  state: State = {
    numPages: null,
    state: 'loading',
  }

  _inputRefImpl = (ref: HTMLDivElement) => {
    this.inputRef = ref;
  }

  _onSuccess = (page: {numPages: number}) => {
    const num_pages = page.numPages;
    if (num_pages == null) {
      this._onError();
      return;
    }

    this.setState({
      numPages: page.numPages,
      state: 'success',
    });
  }

  _onError = () => {
    this.setState({
      state: 'error',
    });
  }

  _displayPage(): ?React.Node {
    const numPages = this.state.numPages;
    if (numPages == null) {
      return null;
    }

    return ([
      <div
        key="1"
        style={{paddingTop: '4px', backgroundColor: '#525659'}}
      />,
      <CVDocumentViewer
        key="2"
        numPages={numPages}
        documentWidth={this.inputRef && this.inputRef.offsetWidth}
      />,
      <div
        key="3"
        style={{paddingBottom: '4px', backgroundColor: '#525659'}}
      />,
    ]);
  }

  _displayError(): ?React.Node {
    if (this.state.state !== 'error') {
      return null;
    }

    return (
      <span>
        {`Error!  View document at: ${process.env.PUBLIC_URL}/cv-eredmiles.pdf`}
      </span>
    );
  }

  render(): React.Node {
    return (
      <Document
        file={cv }
        onLoadSuccess={this._onSuccess}
        onLoadError={this._onError}
        inputRef={this._inputRefImpl}
      >
        {this._displayPage()}
        {this._displayError()}
      </Document>
    );
  }
}

export default CVViewer;
