// @flow strict-local
import './Section.css';
import * as React from 'react';

type Props = {
  children: React.Node,
  className?: ?string,
  label: React.Node,
  hideLabel: boolean,
  displayIframe: boolean,
}

const Section = (props: Props): React.Node => {
  let className = "Section";
  className += props.className != null ? ' ' + props.className : '';
  className += props.hideLabel ? ' SectionHideLabel' : '';
  className += props.displayIframe ? ' SectionDisplayIframe' : '';

  return (
    <div className={className}>
      <div className="SectionLabel">
        <div className="SectionLabelText" >
          {props.label}
        </div>
      </div>
      <div className="SectionContent">
        {props.children}
      </div>
    </div>
  );
}

Section.defaultProps = {
  hideLabel: false,
  displayIframe: false,
}

export default Section;
